<template>
  <ambil-antrean />
  <antrean-dilayani />
</template>

<script>
import AmbilAntrean from '@/components/AmbilAntrean.vue';
import AntreanDilayani from '@/components/AntreanDilayani.vue';
export default {
  name: 'LayarUtamaView',
  components: {
    AmbilAntrean,
    AntreanDilayani
  }
}
</script>

<style></style>