<template>
  <div class="py-5">
    <div class="hstack justify-content-center text-secondary">
      <span class="h1 my-0" style="font-size: 60px;">403</span>
      <div class="vr mx-2" style="width: 5px;"></div>
      <span v-if="$route.params.halaman" class="h1 my-0" style="font-size: 30px;">
        Anda tidak memiliki hak akses ke halaman
        <code class="text-danger text-bg-warning bg-opacity-25">{{ $route.params.halaman }}</code>.
      </span>
      <span v-else class="h1 my-0" style="font-size: 30px;">
        Akses tidak diizinkan.
      </span>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'ForbiddenView',
}
</script>