import { createRouter, createWebHistory } from "vue-router";
import SetToken from "../components/SetToken";
import HomeView from "../views/HomeView.vue";
import LayarUtamaView from "../views/LayarUtamaView";
import LoketView from "../views/LoketView";
import ProfilView from "../views/ProfilView";
import GantiSandiView from "../views/GantiSandiView";
import ForbiddenView from "../views/ForbiddenView";
import NotFound from "../components/NotFound";

const routes = [
    {
        path: "/auth/:token?",
        name: "auth",
        component: SetToken,
        props: (route) => ({ token: route.params.token }),
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/layar-utama/:antrean_id",
        name: "layar-utama",
        component: LayarUtamaView,
    },
    {
        path: "/loket/:antrean_id/:loket_id",
        name: "loket",
        component: LoketView,
    },
    {
        path: "/profil",
        name: "profil",
        component: ProfilView,
    },
    {
        path: "/ganti-sandi",
        name: "ganti-sandi",
        component: GantiSandiView,
    },
    {
        path: "/forbidden/:halaman(.*)?",
        name: "forbidden",
        component: ForbiddenView,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
