<template>
  <div class="fst-italic">Mengautentikasi...</div>
</template>

<script>
export default {
  name: 'SetToken',
  data() {
    return {
    }
  },
  created() {
    if (this.$route.params.token) {
      localStorage.setItem('token', this.$route.params.token);
      window.open(this.$appUrl, '_parent');
    } else if (localStorage.getItem('token')) {
      this.$router.push('/');
    } else {
      window.location.href = this.$sso + '/' + this.$appId
    }
  },
  methods: {
  }
}
</script>

<style></style>