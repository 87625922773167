import { createStore } from "vuex";

export default createStore({
    state: {
        userData: {},
        defaultLimit: 10,
    },
    getters: {
        getDefaultLimit(state) {
            return state.defaultLimit;
        },
    },
    mutations: {
        updateUserData(state, userData) {
            state.userData = userData;
        },
        updateDefaultLimit(state, limit) {
            state.defaultLimit = limit;
        },
    },
    actions: {
        updateUser({ commit }, userData) {
            commit("updateUserData", userData);
        },
        setDefaultLimit({ commit }, batasBaris) {
            commit("updateDefaultLimit", batasBaris);
        },
    },
    modules: {},
});
