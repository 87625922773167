<template>
  <transition name="fade" mode="out-in">
    <h4 v-if="antrean" class="text-center" :key="antrean.nama">{{ antrean.nama }}</h4>
    <h4 v-else class="text-center text-secondary my-2">Antrean tidak ditemukan.</h4>
  </transition>
  <div v-if="antrean && loket" class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto">
    <div class="card shadow text-center">
      <div class="card-header">
        <transition name="fade" mode="out-in">
          <span class="h5" :key="loket.nama">{{ loket.nama }}</span>
        </transition>
      </div>
      <div class="card-body">
        <h6>Antrean Dilayani / Antrean Terakhir</h6>
        <transition name="slide-up" mode="out-in">
          <span class="fs-1 d-inline-block my-0" :key="antrean.prefix + loket.nomor_antrean">
            {{ antrean.prefix + loket.nomor_antrean.toLocaleString('id-ID', {
              minimumIntegerDigits: 3,
              useGrouping: false
            }) }}
          </span>
        </transition>
        <span class="fs-1 my-0">/</span>
        <transition name="slide-up" mode="out-in">
          <span class="fs-1 d-inline-block my-0" :key="antrean.prefix + antrean.jumlah_antrean">
            {{ antrean.prefix + antrean.jumlah_antrean.toLocaleString('id-ID', {
              minimumIntegerDigits: 3,
              useGrouping: false
            }) }}
          </span>
        </transition>
      </div>
      <div class="card-footer">
        <div class="hstack gap-1 justify-content-center">
          <button class="btn btn-sm btn-primary vl-parent" ref="tombolPanggil" @click="panggilAntrean">
            Panggil Antrean</button>
          <div class="vr"></div>
          <button class="btn btn-sm btn-secondary vl-parent" ref="tombolUlangi" @click="ulangiPanggilan">Ulangi
            Panggilan</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="antrean" class="h5 text-center text-secondary mt-3">Loket tidak ditemukan.</div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'LoketView',
  data() {
    return {
      updateListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          if (data.new_status == 0) {
            this.$swal({
              title: 'Perhatian',
              html: `Antrean ini telah dinonaktifkan. Anda akan dialhikan ke halaman utama.`,
              icon: 'warning',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/');
            });
          } else if (data.new_nama) {
            this.antrean.nama = data.new_nama;
            this.antrean.prefix = data.new_prefix;
            this.getDataLoketById();
          } else {
            this.getDataAntreanById();
          }
        }
      },
      escapeListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id
          && this.$route.params.loket_id == data.loket_id && data.user_id == null) {
          this.$swal({
            title: 'Perhatian',
            html: `Loket ini telah dikosongkan. Anda akan dialhikan ke halaman utama.`,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$router.push('/');
          });
        }
      },
      restartListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.antrean.jumlah_antrean = 0;
          this.loket.nomor_antrean = 0;
        }
      },
      deleteListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.$swal({
            title: 'Perhatian',
            html: `Antrean ini telah dihapus. Anda akan dialhikan ke halaman utama.`,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$router.push('/');
          });
        }
      },
      nomorBaruListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.antrean.jumlah_antrean = data.new_antrean;
        }
      },
      isMemuat: false,
      memuat: null,
      antrean: null,
      loket: null,
    }
  },
  mounted() {
    WebSocketService.on("updated-antrean", this.updateListener);
    WebSocketService.on("updated-loket", this.escapeListener);
    WebSocketService.on("restarted-antrean", this.restartListener);
    WebSocketService.on("deleted-antrean", this.deleteListener);
    WebSocketService.on("updated-jumlah-antrean", this.nomorBaruListener);
    window.addEventListener('beforeunload', this.keluarLoket);
    this.getDataAntreanById();
  },
  beforeUnmount() {
    WebSocketService.off("updated-antrean", this.updateListener);
    WebSocketService.off("updated-loket", this.escapeListener);
    WebSocketService.off("restarted-antrean", this.restartListener);
    WebSocketService.off("deleted-antrean", this.deleteListener);
    WebSocketService.off("updated-jumlah-antrean", this.nomorBaruListener);
    window.removeEventListener('beforeunload', this.keluarLoket);
  },
  beforeRouteLeave() {
    this.keluarLoket();
  },
  watch: {
  },
  methods: {
    async getDataAntreanById() {
      this.isMemuat = true;
      this.memuat = this.$loading.show({
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/id', {
          antrean_id: this.$route.params.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.antrean = response.data.antrean;
          this.masukLoket();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Perhatian',
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
        setTimeout(() => {
          this.isMemuat = false;
          this.memuat.hide();
        }, 250);
      }
    },
    async masukLoket() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/masuk', {
          loket_id: this.$route.params.loket_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.getDataLoketById();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.push('/');
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
        setTimeout(() => {
          this.isMemuat = false;
          this.memuat.hide();
        }, 250);
      }
    },
    async getDataLoketById() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/id', {
          loket_id: this.$route.params.loket_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.loket = response.data.loket;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Perhatian',
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonText: 'Baik'
            });
            this.loket = null;
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        if (this.isMemuat) {
          this.isMemuat = false;
          this.memuat.hide();
        }
      }, 250);
    },
    async keluarLoket() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        await this.axios.post('/loket/keluar', {
          loket_id: this.$route.params.loket_id
        }, {
          headers: headers
        });
      }
      catch (err) {
        console.log(err)
      }
    },
    async panggilAntrean() {
      let memuat = this.$loading.show({
        container: this.$refs.tombolPanggil,
        loader: 'bars',
        height: 25,
        'z-index': 1
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/panggil', {
          loket_id: this.$route.params.loket_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.loket.nomor_antrean = response.data.nomor_antrean;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Perhatian',
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async ulangiPanggilan() {
      let memuat = this.$loading.show({
        container: this.$refs.tombolUlangi,
        loader: 'bars',
        height: 25,
        'z-index': 1
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/ulangi', {
          loket_id: this.$route.params.loket_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.loket.nomor_antrean = response.data.nomor_antrean;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Perhatian',
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>