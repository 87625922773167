import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-antrean.com:3005";
// app.config.globalProperties.$auth = "http://auth.com:3000";
// app.config.globalProperties.$sso = "http://sso.com:200";
// app.config.globalProperties.$appUrl = "http://antrean.com:205";
// app.config.globalProperties.$appId = "5e7f29ca-10cd-11ef-801e-ac120340b234";

// axios.defaults.baseURL = "http://backend-antrean.local";
// app.config.globalProperties.$auth = "http://auth.local";
// app.config.globalProperties.$sso = "http://sso.local";
// app.config.globalProperties.$appUrl = "http://antrean.local";
// app.config.globalProperties.$appId = "f4c4147a-271d-11ef-abda-2b8d42a26824";

axios.defaults.baseURL = "https://localbackend-antrean.sitepgri.com";
app.config.globalProperties.$auth = "https://localauth.sitepgri.com";
app.config.globalProperties.$sso = "https://sso.sitepgri.com";
app.config.globalProperties.$appUrl = "https://antrean.sitepgri.com";
app.config.globalProperties.$appId = "9a872bf3-57b8-11ee-a065-ee52fd8f2043";

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.component("v-select", vSelect);

app.mount("#app");
