<template>
  <div class="offcanvas show z-1" :class="offcanvasClass" data-bs-scroll="true" data-bs-backdrop="false" tabindex="1"
    id="ambil">
    <div class="offcanvas-header">
      <transition name="fade" mode="out-in">
        <h6 v-if="antrean" class="text-center px-2 w-100" :key="antrean.nama">{{ antrean.nama }}</h6>
      </transition>
    </div>
    <div class="offcanvas-body">
      <div class="position-absolute top-50 start-50 translate-middle w-100">
        <div v-if="antrean && antrean.status" class="row justify-content-center px-3">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <div class="card shadow text-center">
              <div class="card-header">
                <span class="h6">Antrean Terakhir</span>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="antrean.prefix + antrean.jumlah_antrean">{{ antrean.prefix +
                    antrean.jumlah_antrean.toLocaleString('id-ID', {
                      minimumIntegerDigits: 3,
                      useGrouping: false
                    }) }}</span>
                </transition>
              </div>
              <div class="card-footer py-1">
                <button class="btn btn-sm btn-primary px-3" @click="ambilAntreanBaru">Ambil</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="antrean" class="row justify-content-center px-3">
          <h6 class="text-center text-secondary">Antrean Nonaktif</h6>
        </div>
        <div v-else class="row justify-content-center px-3">
          <h6 class="text-center text-secondary">Antrean tidak ditemukan</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'AmbilAntrean',
  data() {
    return {
      updateListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          if (data.new_status == 0) {
            this.$swal({
              title: 'Perhatian',
              html: `Antrean ini telah dinonaktifkan. Anda akan dialhikan ke halaman utama.`,
              icon: 'warning',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/');
            });
          } else if (data.new_nama) {
            this.antrean.nama = data.new_nama;
            this.antrean.prefix = data.new_prefix;
          } else {
            this.getDataAntreanById();
          }
        }
      },
      restartListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.antrean.jumlah_antrean = 0;
        }
      },
      deleteListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.$swal({
            title: 'Perhatian',
            html: `Antrean ini telah dihapus. Anda akan dialhikan ke halaman utama.`,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$router.push('/');
          });
        }
      },
      nomorBaruListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.antrean.jumlah_antrean = data.new_antrean;
        }
      },
      offcanvasClass: '',
      lebar: window.innerWidth,
      antrean: null,
    }
  },
  mounted() {
    WebSocketService.on("updated-antrean", this.updateListener);
    WebSocketService.on("restarted-antrean", this.restartListener);
    WebSocketService.on("deleted-antrean", this.deleteListener);
    WebSocketService.on("updated-jumlah-antrean", this.nomorBaruListener);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getDataAntreanById();
  },
  beforeUnmount() {
    WebSocketService.off("updated-antrean", this.updateListener);
    WebSocketService.off("restarted-antrean", this.restartListener);
    WebSocketService.off("deleted-antrean", this.deleteListener);
    WebSocketService.off("updated-jumlah-antrean", this.nomorBaruListener);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {

  },
  watch: {

  },
  methods: {
    handleResize() {
      this.lebar = window.innerWidth;
      if (this.lebar < 400) {
        this.offcanvasClass = 'offcanvas-top w-100 h-50 mt-5'
      } else if (this.lebar < 700) {
        this.offcanvasClass = 'offcanvas-start w-50 mt-5'
      } else {
        this.offcanvasClass = 'offcanvas-start mt-5'
        document.getElementById('ambil').style.width = (this.lebar - 350) + 'px'
      }
    },
    async getDataAntreanById() {
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/id', {
          antrean_id: this.$route.params.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.antrean = response.data.antrean;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async ambilAntreanBaru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/ambil', {
          antrean_id: this.$route.params.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          console.log(response.data)
          const tgl = new Date();
          const printWindow = window.open('', '_blank');
          printWindow.document.write(`<html><head><title>Cetak</title>
          	<style>
          	@media print {
              body{
                font-family: 'Trebuchet MS', sans-serif;
              }
          		.header {
          			text-align: center;
          			font-size: 10pt;
          		}
          		.footer {
          			text-align: center;
          			font-size: 8pt;
          		}
          		.nomor {
          			margin: 20 0 20 0;
          			text-align: center;
          		}
          	}
          	</style>
          	</head><body>
          	<div class="header">${this.antrean.nama}</div>
          	<div class="nomor">
          		<span style="font-size: 8pt;">Nomor Antrean</span>
          		<h1 style="margin: 0; font-size: 30pt;">${this.antrean.prefix + this.antrean.jumlah_antrean.toLocaleString('id-ID', {
            minimumIntegerDigits: 3,
            useGrouping: false
          })}</h1>
          	</div>
          	<div class="footer">
          		<h5 style="margin-bottom:5px;">Aplikasi Antrean SMK PGRI 1 KEDIRI</h5>
          		<span style="font-size: 6pt;">${tgl.toLocaleString('id-ID', {
            dateStyle: "full",
            timeStyle: "long"
          })}</span>
          	</div>
          	</body></html>`);
          printWindow.document.close();

          printWindow.print({
            orientation: 'portrait',
          });
          printWindow.close();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
  }
}
</script>

<style scoped></style>