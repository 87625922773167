<template>
  <div class="offcanvas show text-bg-dark z-1" :class="offcanvasClass" data-bs-scroll="true" data-bs-backdrop="false"
    tabindex="1" id="antrean">
    <div class="offcanvas-body small p-0" data-bs-theme="dark">
      <div class="list-group list-group-flush border-bottom scrollarea bg-body-tertiary mb-3 d-flex">
        <transition-group name="list-group-item" tag="div" mode="out-in">
          <div v-for="l in listLoket" :key="l" class="list-group-item py-3 lh-sm" aria-current="true">
            <div class="d-flex w-100 align-items-center justify-content-between">
              <div>
                <h6 class="mb-1">{{ l.nama_loket }}</h6>
                <transition name="fade" mode="out-in">
                  <small :key="l.nama_petugas">{{ l.nama_petugas }}</small>
                </transition>
              </div>
              <div>
                <transition name="slide-up" mode="out-in">
                  <span class="h3 d-inline-block my-0" :key="l.prefix + l.nomor_antrean">{{ l.prefix +
                    l.nomor_antrean.toLocaleString('id-ID',
                      {
                        minimumIntegerDigits: 3,
                        useGrouping: false
                      }) }}</span>
                </transition>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'AntreanDilayani',
  data() {
    return {
      updateAntreanListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          if (data.new_status == 0) {
            this.listLoket = [];
          } else {
            this.getDaftarLoket();
          }
        }
      },
      restartAntreanListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          this.getDaftarLoket();
        }
      },
      updateLoketListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          for (const l of this.listLoket) {
            if (l.loket_id == data.loket_id) {
              l.nama_petugas = data.nama_admin || data.nama_guru || '-';
              break;
            }
          }
        }
      },
      panggilListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          const indexLoket = this.listLoket.findIndex((item) => item.loket_id == data.loket_id);
          if (indexLoket >= 0) {
            let loket = this.listLoket[indexLoket];
            loket.nomor_antrean = data.next_antrean;
            this.speak(`Panggilan untuk nomor antrean ${loket.prefix}!${loket.nomor_antrean}, silahkan menuju ke ${loket.nama_loket}!`);
            this.listLoket.splice(indexLoket, 1);
            this.listLoket.unshift(loket);
          }
        }
      },
      ulangiListener: (d) => {
        const data = JSON.parse(d);
        if (this.$route.params.antrean_id == data.antrean_id) {
          const indexLoket = this.listLoket.findIndex((item) => item.loket_id == data.loket_id);
          if (indexLoket >= 0) {
            let loket = this.listLoket[indexLoket];
            loket.nomor_antrean = data.ulangi_panggilan;
            this.speak(`Panggilan untuk nomor antrean ${loket.prefix}!${loket.nomor_antrean}, silahkan menuju ke ${loket.nama_loket}!`);
            this.listLoket.splice(indexLoket, 1);
            this.listLoket.unshift(loket);
          }
        }
      },
      offcanvasClass: '',
      lebar: window.innerWidth,
      listLoket: [],
    }
  },
  mounted() {
    WebSocketService.on("updated-antrean", this.updateAntreanListener);
    WebSocketService.on("restarted-antrean", this.restartAntreanListener);
    WebSocketService.on("updated-loket", this.updateLoketListener);
    WebSocketService.on("panggil-antrean", this.panggilListener);
    WebSocketService.on("ulangi-panggilan", this.ulangiListener);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getDaftarLoket();
  },
  beforeUnmount() {
    WebSocketService.off("updated-antrean", this.updateAntreanListener);
    WebSocketService.off("restarted-antrean", this.restartAntreanListener);
    WebSocketService.off("updated-loket", this.updateLoketListener);
    WebSocketService.off("panggil-antrean", this.panggilListener);
    WebSocketService.off("ulangi-panggilan", this.ulangiListener);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {

  },
  watch: {

  },
  methods: {
    handleResize() {
      this.lebar = window.innerWidth;
      if (this.lebar < 400) {
        this.offcanvasClass = 'offcanvas-bottom w-100 h-50'
      } else if (this.lebar < 700) {
        this.offcanvasClass = 'offcanvas-end w-50 mt-5'
      } else {
        this.offcanvasClass = 'offcanvas-end mt-5'
        document.getElementById('antrean').style.width = '350px'
      }
    },
    async getDaftarLoket() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/list', {
          antrean_id: this.$route.params.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.listLoket = response.data.loket;
          for (const l of this.listLoket) {
            l.nama_petugas = l.nama_admin || l.nama_guru || '-'
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    speak(teks) {
      if ('speechSynthesis' in window) {
        const speechSynthesis = window.speechSynthesis;
        const speechText = new SpeechSynthesisUtterance(teks);
        speechText.lang = 'id-ID';

        speechSynthesis.speak(speechText);
      } else {
        console.log('Web Speech API tidak didukung di browser ini.');
      }
    }
  }
}
</script>

<style scoped>
.list-group-item {
  transition: transform 0.5s;
}

.list-group-item-move {
  transition: transform 0.5s;
}
</style>