<template>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between mx-auto gap-2">
        <div class="col-auto p-0 d-inline-flex">
          <h5 class="my-auto">Data Antrean</h5>
        </div>
        <div class="col-auto p-0">
          <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
            data-bs-target="#tambahData">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
            </svg>Tambahkan Antrean
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="tercentang.length" class="mb-2 text-end">
        <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
      </div>
      <div class="table-responsive shadow vl-parent" ref="table">
        <table class="table table-sm table-striped table-hover mb-0">
          <thead class="table-secondary align-middle">
            <tr>
              <th>#</th>
              <th class="position-sticky start-0">Nama Kegiatan</th>
              <th>Nama Loket</th>
              <th>Jumlah Loket</th>
              <th>Antrean</th>
              <th>Aktif</th>
              <th>Akses</th>
              <th v-if="user.role === 'admin'">
                <div class="d-flex justify-content-between">
                  Tindakan
                  <input class="form-check-input" type="checkbox" v-model="semuaTercentang" @click="centangSemua">
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="dataAntrean.length">
            <tr v-for="d, index in dataAntrean" :key="d">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0">{{ d.nama_antrean }}</td>
              <td>{{ d.nama_loket }}</td>
              <td>{{ d.jumlah_loket }}</td>
              <td>{{ d.prefix + d.antrean_terakhir.toLocaleString('id-ID', {
                minimumIntegerDigits: 3,
                useGrouping: false
              }) + "/" + d.prefix + d.jumlah_antrean.toLocaleString('id-ID', {
                minimumIntegerDigits: 3,
                useGrouping: false
              }) }}</td>
              <td>
                <div class="form-check form-switch">
                  <input v-if="user.role === 'admin' && (user.ket === 'it' || user.admin_id == d.created_by)"
                    class="form-check-input" type="checkbox" role="switch" :checked="d.status"
                    @change="aktifkanStatus(d)">
                  <input v-else class="form-check-input" type="checkbox" role="switch" :checked="d.status" disabled>
                </div>
              </td>
              <td>
                <div class="hstack gap-1 me-1">
                  <button v-if="d.status" class="btn btn-sm btn-outline-dark d-flex" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" data-bs-title="Loket" @click="getDaftarLoket(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                      class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                      <path
                        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                    </svg>
                  </button>
                  <button v-else class="btn btn-sm btn-outline-dark d-flex" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" data-bs-title="Loket" disabled>
                    <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                      class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                      <path
                        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button v-if="d.status" class="btn btn-sm btn-outline-dark d-flex" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" data-bs-title="Layar Utama" data-bs-dismiss="tooltip"
                    @click="keTampilanLayar(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-easel2-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M8.447.276a.5.5 0 0 0-.894 0L7.19 1H2.5A1.5 1.5 0 0 0 1 2.5V10h14V2.5A1.5 1.5 0 0 0 13.5 1H8.809L8.447.276Z" />
                      <path fill-rule="evenodd"
                        d="M.5 11a.5.5 0 0 0 0 1h2.86l-.845 3.379a.5.5 0 0 0 .97.242L3.89 14h8.22l.405 1.621a.5.5 0 0 0 .97-.242L12.64 12h2.86a.5.5 0 0 0 0-1H.5Zm3.64 2 .25-1h7.22l.25 1H4.14Z" />
                    </svg>
                  </button>
                  <button v-else class="btn btn-sm btn-outline-dark d-flex" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" data-bs-title="Layar Utama" disabled>
                    <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-easel2-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M8.447.276a.5.5 0 0 0-.894 0L7.19 1H2.5A1.5 1.5 0 0 0 1 2.5V10h14V2.5A1.5 1.5 0 0 0 13.5 1H8.809L8.447.276Z" />
                      <path fill-rule="evenodd"
                        d="M.5 11a.5.5 0 0 0 0 1h2.86l-.845 3.379a.5.5 0 0 0 .97.242L3.89 14h8.22l.405 1.621a.5.5 0 0 0 .97-.242L12.64 12h2.86a.5.5 0 0 0 0-1H.5Zm3.64 2 .25-1h7.22l.25 1H4.14Z" />
                    </svg>
                  </button>
                </div>
              </td>
              <td v-if="user.role === 'admin'">
                <div class="d-flex justify-content-between" v-if="user.ket === 'it' || user.admin_id == d.created_by">
                  <div class="hstack gap-1 me-1">
                    <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)" data-bs-toggle="tooltip"
                      data-bs-placement="bottom" data-bs-title="Edit">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                      data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Hapus">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                        viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button v-if="d.status" class="btn btn-sm btn-outline-success d-flex" @click="confirmRestart(d)"
                      data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Mulai Ulang">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-arrow-repeat"
                        viewBox="0 0 16 16">
                        <path
                          d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                      </svg>
                    </button>
                    <button v-else class="btn btn-sm btn-outline-success d-flex" disabled data-bs-toggle="tooltip"
                      data-bs-placement="bottom" data-bs-title="Mulai Ulang">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-arrow-repeat"
                        viewBox="0 0 16 16">
                        <path
                          d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button type="button" class="btn btn-sm btn-outline-secondary d-flex" data-bs-container="body"
                      data-bs-toggle="popover" data-bs-trigger="focus" data-bs-placement="left"
                      :data-bs-title="d.nama_antrean" data-bs-html="true" :data-bs-content="`<small>
                      Dibuat: <i>${d.created_at}</i><br/>Oleh: <strong>${d.creator}</strong><br/>Diperbarui: <i>${d.updated_at}</i><br/>Oleh: <strong>${d.updater}</strong>
                      </small>`">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-info-lg"
                        viewBox="0 0 16 16">
                        <path
                          d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                      </svg>
                    </button>
                  </div>
                  <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatu(d)">
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="8">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="dataAntrean.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataAntrean.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="dataAntrean.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="  l   in   arrayLimit  " :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="  p   in    pageCount   " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)
                " class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)
                " class="page-link px-1" @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Antrean</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Antrean/Kegiatan"
                  v-model="formCreate.nama" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode Awalan</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode Awalan"
                  v-model="formCreate.prefix" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama Loket</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Loket"
                  v-model="formCreate.nama_loket" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jumlah Loket</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Jumlah Loket"
                  v-model="formCreate.jumlah_loket" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Antrean</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Antrean/Kegiatan"
                  v-model="formEdit.nama_antrean" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode Awalan</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode Awalan"
                  v-model="formEdit.prefix" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama Loket</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Loket"
                  v-model="formEdit.nama_loket" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jumlah Loket</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Jumlah Loket"
                  v-model="formEdit.jumlah_loket" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="daftarLoket" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="daftarLoket">
        <div class="modal-header">
          <legend class="modal-title">Masuk Pebagai Petugas Loket</legend>
          <button type="button" id="tutupDaftarLoket" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <h5>{{ antreanLoketDisplayed.nama_antrean }}</h5>
          <table class="table table-sm table-hover table-striped shadow align-middle">
            <thead class="table-secondary">
              <tr>
                <th>Loket</th>
                <th>Petugas</th>
                <th>Antrean</th>
                <th>Masuk</th>
                <th v-if="user.role === 'admin'">Kosongkan</th>
              </tr>
            </thead>
            <tbody v-if="listLoket.length">
              <tr v-for="d in listLoket" :key="d">
                <td>{{ d.nama_loket }}</td>
                <td>{{ d.nama_petugas }}</td>
                <td>{{ d.prefix + d.nomor_antrean.toLocaleString('id-ID', {
                  minimumIntegerDigits: 3,
                  useGrouping: false
                }) }}</td>
                <td>
                  <button v-if="!d.user_id" class="btn btn-sm btn-outline-dark border-0"
                    @click="this.$router.push('/loket/' + d.antrean_id + '/' + d.loket_id)" data-bs-dismiss="modal">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor"
                      class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                  </button>
                  <button v-else class="btn btn-sm btn-outline-dark border-0" disabled>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor"
                      class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                  </button>
                </td>
                <td v-if="user.role === 'admin'">
                  <button v-if="d.user_id" class="btn btn-sm btn-outline-danger border-0" @click="confirmEscape(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-escape"
                      viewBox="0 0 16 16">
                      <path
                        d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                      <path
                        d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                    </svg>
                  </button>
                  <button v-else class="btn btn-sm btn-outline-danger border-0" disabled>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-escape"
                      viewBox="0 0 16 16">
                      <path
                        d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                      <path
                        d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal, Popover } from 'bootstrap';
import WebSocketService from './WebSocketService';
export default {
  name: 'DataAntrean',
  data() {
    return {
      insertListener: (d) => {
        if (d) {
          this.getDataAntrean();
        }
      },
      updateListener: (d) => {
        const data = JSON.parse(d);
        let listLoketTerbuka = false;
        const modalDaftarLoket = document.getElementById('daftarLoket');
        for (const c of modalDaftarLoket.classList) {
          if (c == 'show') {
            listLoketTerbuka = true;
            break;
          }
        }
        if (this.antreanLoketDisplayed.antrean_id == data.antrean_id && listLoketTerbuka) {
          if (data.new_status == 0) {
            this.listLoket = [];
          } else if (data.new_nama) {
            this.antreanLoketDisplayed.nama_antrean = data.new_nama;
            this.getDaftarLoket(this.antreanLoketDisplayed);
          } else {
            this.getDaftarLoket(this.antreanLoketDisplayed);
          }
        }
        let ditemukan = false;
        for (const a of this.dataAntrean) {
          if (data.antrean_id == a.antrean_id) {
            ditemukan = true;
            break;
          }
        }
        if (ditemukan) {
          this.getDataAntrean()
        }
      },
      restartListener: (d) => {
        const data = JSON.parse(d);
        let ditemukan = false;
        for (const a of this.dataAntrean) {
          if (data.antrean_id == a.antrean_id) {
            ditemukan = true;
            break;
          }
        }
        if (ditemukan) {
          this.getDataAntrean()
        }
      },
      deleteListener: () => {
        document.getElementById('tutupDaftarLoket').click();
        if (this.pageCurrent == 1) {
          this.getDataAntrean();
        } else {
          this.pageCurrent = 1;
        }
      },
      updateLoketListener: (d) => {
        const data = JSON.parse(d);
        if (this.listLoket.length) {
          for (const a of this.listLoket) {
            if (data.loket_id == a.loket_id) {
              a.user_id = data.user_id;
              a.nama_petugas = data.nama_admin || data.nama_guru || '-';
              break;
            }
          }
        }
      },
      nomorBaruListener: (d) => {
        const data = JSON.parse(d);
        for (const a of this.dataAntrean) {
          if (data.antrean_id == a.antrean_id) {
            a.jumlah_antrean = data.new_antrean;
            break;
          }
        }
      },
      panggilanListener: (d) => {
        const data = JSON.parse(d);
        for (const a of this.dataAntrean) {
          if (data.antrean_id == a.antrean_id) {
            a.antrean_terakhir = data.next_antrean;
            break;
          }
        }
      },
      user: "",
      dataAntrean: [],
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      formCreate: {
        nama: null,
        prefix: null,
        nama_loket: null,
        jumlah_loket: null,
      },
      selectedData: {},
      formEdit: {},
      tercentang: [],
      semuaTercentang: false,
      antreanLoketDisplayed: {},
      listLoket: [],
    }
  },
  mounted() {
    WebSocketService.on("inserted-antrean", this.insertListener);
    WebSocketService.on("updated-antrean", this.updateListener);
    WebSocketService.on("restarted-antrean", this.restartListener);
    WebSocketService.on("deleted-antrean", this.deleteListener);
    WebSocketService.on("updated-loket", this.updateLoketListener);
    WebSocketService.on("updated-jumlah-antrean", this.nomorBaruListener);
    WebSocketService.on("panggil-antrean", this.panggilanListener);
    this.user = this.userData;
    this.pageLimit = this.batasBaris;
    this.getDataAntrean();
    this.tooltip = new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  beforeUnmount() {
    WebSocketService.off("inserted-antrean", this.insertListener);
    WebSocketService.off("updated-antrean", this.updateListener);
    WebSocketService.off("restarted-antrean", this.restartListener);
    WebSocketService.off("deleted-antrean", this.deleteListener);
    WebSocketService.off("updated-loket", this.updateLoketListener);
    WebSocketService.off("updated-jumlah-antrean", this.nomorBaruListener);
    WebSocketService.off("panggil-antrean", this.panggilanListener);
  },
  updated() {
    Array.from(document.querySelectorAll('button[data-bs-toggle="popover"]'))
      .forEach(popoverNode => new Popover(popoverNode))
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    userData() {
      this.user = this.userData;
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataAntrean();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataAntrean();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getDataAntrean() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/list', {
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: headers
        });
        if (response.data) {
          this.dataAntrean = response.data.antrean;
          for (const a of this.dataAntrean) {
            a.created_at = a.created_at ? new Date(a.created_at).toLocaleString('id-ID') : '-';
            a.creator = a.creator || '-';
            a.updated_at = a.updated_at ? new Date(a.updated_at).toLocaleString('id-ID') : '-';
            a.updater = a.updater || '-';
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          this.tercentang = [];
          this.semuaTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/tambah', this.formCreate, {
          headers: headers
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              nama: null,
              jumlah_jam: null,
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    keTampilanLayar(d) {
      this.$swal.fire({
        html: `Masuk ke tampilan utama <b>${d.nama_antrean}</b>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        didOpen: () => {
          this.$swal.showLoading();
        },
      }).then(() => {
        const tooltipsEl = document.getElementsByClassName('tooltip');
        for (const t of tooltipsEl) {
          t.remove();
        }
        this.$router.push('/layar-utama/' + d.antrean_id);
      });
    },
    async getDaftarLoket(d) {
      this.antreanLoketDisplayed = d;
      let listLoketTerbuka = false;
      const modalDaftarLoket = document.getElementById('daftarLoket');
      for (const c of modalDaftarLoket.classList) {
        if (c == 'show') {
          listLoketTerbuka = true;
          break;
        }
      }
      if (!listLoketTerbuka) {
        const listLoketModal = new Modal(document.getElementById('daftarLoket'));
        listLoketModal.show();
      }
      let memuat = this.$loading.show({
        container: this.$refs.daftarLoket,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/list', {
          antrean_id: d.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.listLoket = response.data.loket;
          for (const l of this.listLoket) {
            l.nama_petugas = l.nama_admin || l.nama_guru || '-'
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    masukLoket(d) {
      this.$router.push('/loket/' + d.antrean_id + '/' + d.loket_id);
    },
    confirmEscape(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin mengosongkan <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_loket}</code>?</span>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.kosongkanLoket(d);
        }
      });
    },
    async kosongkanLoket(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/loket/kosongkan', {
          loket_id: d.loket_id
        }, {
          headers: headers
        });
        if (response.data) {
          document.getElementById('tutupDaftarLoket').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk mengosongkan loket.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      const editModal = new Modal(document.getElementById('editData'));
      editModal.show();
      this.selectedData = { ...d };
      this.formEdit = { ...this.selectedData };
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        this.formEdit = {
          ...this.formEdit,
          nama: this.formEdit.nama_antrean,
        }
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/antrean/perbarui', this.formEdit, { headers: headers });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: 'Berhasil memperbarui data Antrean',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else if (err.response.status == 404) {
              this.$swal({
                title: 'Gagal',
                text: 'Data tidak ditemukan.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    aktifkanStatus(d) {
      const a = d.status;
      d.status = d.status == 1 ? 0 : 1;
      if (a) {
        this.$swal({
          title: 'Konfirmasi',
          html: `<span class="h5">Anda yakin ingin menonaktifkan Antrean <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_antrean}</code>?</span>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateStatus(d);
          } else {
            d.status = 1;
          }
        });
      } else {
        this.$swal({
          title: 'Konfirmasi',
          html: `<span class="h5">Anda yakin ingin mengaktifkan Antrean <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_antrean}</code>?</span>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateStatus(d);
          } else {
            d.status = 0;
          }
        });
      }
    },
    async updateStatus(d) {
      const info = d.status ? 'mengaktifkan' : 'menonaktifkan';
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/perbarui-status', {
          antrean_id: d.antrean_id,
          status: d.status
        }, { headers: headers });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: `Berhasil ${info} Antrean ${d.nama_antrean}`,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
        d.status = d.status ? 0 : 1;
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Antrean <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_antrean}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/hapus', {
          antrean_id: d.antrean_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmRestart(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin memulai ulang Antrean <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_antrean}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Mulai Ulang',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.mulaiUlangData(d);
        }
      });
    },
    async mulaiUlangData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/antrean/mulai-ulang', {
          antrean_id: d.antrean_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(d) {
      const ditemukan = this.tercentang.findIndex((j) =>
        j == d.antrean_id
      );
      if (ditemukan >= 0) {
        this.tercentang.splice(ditemukan, 1);
      } else {
        this.tercentang.push(d.antrean_id);
      }
      if (this.tercentang.length == this.dataAntrean.length) {
        this.semuaTercentang = true;
      } else {
        this.semuaTercentang = false;
      }
    },
    centangSemua() {
      this.tercentang = []
      if (this.semuaTercentang) {
        for (const d of this.dataAntrean) {
          d.centang = false;
          this.tercentang = [];
        }
      } else {
        for (const d of this.dataAntrean) {
          d.centang = true;
          this.tercentang.push(d.antrean_id);
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data Antrean terpilih?<br/>
						&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected()
        }
      });
    },
    async deleteSelected() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      }; try {
        const response = await this.axios.post("/antrean/hapus", {
          listAntreanId: this.tercentang,
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else if (err.response.status == 404) {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>