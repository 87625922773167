<template>
  <h4>Aplikasi Antrean SMK PGRI 1 KEDIRI</h4>
  <data-antrean />
</template>

<script>
// @ is an alias to /src
import DataAntrean from '@/components/DataAntrean.vue';

export default {
  name: 'HomeView',
  components: {
    DataAntrean
  },
  mounted() {
  }
}
</script>
